import {
  ErrorBoundary,
  type ComponentProps,
  type ValidComponent,
} from "solid-js";

export function ProjectErrorBoundary(props: ComponentProps<ValidComponent>) {
  return (
    <ErrorBoundary
      fallback={(err, reset) => (
        <div class="todo" onClick={reset}>
          Error: {err.toString()}
        </div>
      )}
    >
      {props.children}
    </ErrorBoundary>
  );
}
